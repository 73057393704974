import React, { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { Tooltip, Typography } from '@material-ui/core'

import Priority from '_/components/priority'
import { ActionDeadline } from '_/components/service-order'
import { Classification } from '_/components/classification'

import { ContactButton } from '../buttons/ContactButton'

import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
import { classificationsServiceOrderSelector } from '_/modules/classification/selectors'

import classNames from 'classnames'

import useStyles from './styles'
import useRolePermission from '_/hooks/use-role-permission'

export function InfoBar() {
  const styles = useStyles()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const classifications = useSelector(classificationsServiceOrderSelector)
  const { isTradesman } = useRolePermission()

  const renderCategories = useMemo(() => {
    if (!serviceOrder?.get('category'))
      return <span className={styles.itemText}>Sem categoria</span>

    const categories = serviceOrder?.get('category')
    const mainCategoryName = categories?.first()?.get('name') ?? 'Sem categoria'
    const extraCategories = categories?.slice(1)
    const subcategories = extraCategories.reduce((acc, curr, index) => {
      const last = serviceOrder.category.size - 2 === index
      return `${acc} ${curr.get('name')}${last ? '' : ','}`
    }, '')

    return (
      <>
        <span className={styles.itemText}>{mainCategoryName}</span>
        {extraCategories.size > 0 && (
          <Tooltip arrow title={subcategories} placement="top">
            <Typography color="primary" className={styles.text}>
              + {extraCategories.size}
            </Typography>
          </Tooltip>
        )}
      </>
    )
  }, [serviceOrder?.get('category')])

  const renderPriority = useMemo(() => {
    const priority = serviceOrder?.priority
    return priority ? (
      <div className={classNames(styles.itemContent, styles.priority)}>
        <Priority value={priority} />
      </div>
    ) : (
      <span className={styles.itemText}>Sem prioridade</span>
    )
  }, [serviceOrder?.priority])

  const renderClassifications = useMemo(() => {
    if (!classifications || isTradesman) return null

    return (
      <div className={styles.item}>
        <span className={styles.label}>Classificações</span>
        <div className={classNames(styles.itemContent)}>
          <Classification classifications={classifications} />
        </div>
      </div>
    )
  }, [classifications, isTradesman])

  if (!serviceOrder?.id) return null

  return (
    <div className={styles.container}>
      <div className={classNames(styles.item, styles.deadline)}>
        <ActionDeadline showHelpRequestButton={false} />
      </div>
      <div className={styles.item}>
        <span className={styles.label}>Prioridade</span>
        {renderPriority}
      </div>
      <div className={styles.item}>
        <span className={styles.label}>Categorias</span>
        <div className={classNames(styles.itemContent, styles.category)}>{renderCategories}</div>
      </div>
      {renderClassifications}
      <div className={styles.rightContent}>
        <ContactButton />
      </div>
    </div>
  )
}
