import React, { useCallback, useMemo } from 'react'

import { Button } from '@refera/ui-web'
import { useLocation } from '@reach/router'
import useRolePermission from '_/hooks/use-role-permission'
import { useBudgetForm } from '_/views/budget-view-v2/hooks/use-budget-form'

export default function SendButton() {
  const { pathname } = useLocation()
  const { isTradesman } = useRolePermission()

  const isCreating = useMemo(() => pathname.match(/\/novo$/), [])
  const { isEditing } = useBudgetForm()

  const showSaveButton = isTradesman && !isCreating && !isEditing

  const handleSave = useCallback(() => {}, [])

  return (
    showSaveButton && (
      <Button onClick={handleSave} color="primary" variant="primary">
        Enviar
      </Button>
    )
  )
}
