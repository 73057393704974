import React, { useCallback, useMemo, useState } from 'react'

import { Button } from '@refera/ui-web'
import { navigate, useLocation, useParams } from '@reach/router'
import { useDispatch } from 'react-redux'

import { ROUTES } from '_/utils/constants'
import { getVerifyTradesmanInstallments } from '_/modules/finance/actions'
import { useToast } from '_/hooks/use-toast'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import useRolePermission from '_/hooks/use-role-permission'
import { useBudgetForm } from '_/views/budget-view-v2/hooks/use-budget-form'

const ViewInstallmentsButton = () => {
  const dispatch = useDispatch()

  const { serviceOrderId } = useParams()

  const { showToast } = useToast()
  const { isTradesman } = useRolePermission()
  const { pathname } = useLocation()
  const isCreating = useMemo(() => pathname.match(/\/novo$/), [])
  const { isEditing } = useBudgetForm()

  const [isLoading, setIsLoading] = useState(false)

  const showButton = isTradesman && !isCreating && !isEditing

  const fetchVerifyTradesmanInstallments = useCallback(() => {
    setIsLoading(true)
    dispatch(getVerifyTradesmanInstallments(serviceOrderId))
      .then(() => navigate(`${ROUTES.INSTALLMENTS_RECEIPTS}/?chamado=${serviceOrderId}`))
      .catch(error =>
        showToast({
          type: 'error',
          message: formatErrorMessage(error),
        })
      )
      .finally(() => setIsLoading(false))
  }, [dispatch, serviceOrderId])

  if (!showButton) return null

  return (
    <Button variant="secondary" onClick={fetchVerifyTradesmanInstallments} disabled={isLoading}>
      Ver parcelas
    </Button>
  )
}

export default ViewInstallmentsButton
