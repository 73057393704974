import React from 'react'
import { navigate, useLocation, useParams } from '@reach/router'

import { Button } from '@refera/ui-web'

import { useBudgetForm } from '_/views/budget-view-v2/hooks/use-budget-form'

import { useToast } from '_/hooks/use-toast'
import useRolePermission from '_/hooks/use-role-permission'

import { ROUTES } from '_/utils/constants'
import { BUTTONS_ACTION } from '_/utils/constants/service-order'
import { CanDoButtonAction } from '_/utils/can-do-button-action'

export default function NFSEButton() {
  const { pathname } = useLocation()
  const { budgetId, serviceOrderId } = useParams()

  const { isEditing } = useBudgetForm()
  const { isAdmin, isSAAS, isTradesman } = useRolePermission()

  const { showToast } = useToast()

  const showButton = !isEditing && (isAdmin || isSAAS || isTradesman) && budgetId

  const buttonAction = isTradesman
    ? BUTTONS_ACTION.EDIT_BUDGET_BY_PROVIDER
    : BUTTONS_ACTION.EDIT_BUDGET

  const ableToSendNF = CanDoButtonAction({
    nameButton: buttonAction,
  })

  function handleClick() {
    if (!ableToSendNF) {
      showToast({
        type: 'error',
        message: 'Este orçamento está em uma etapa que não permite inclusão de nota fiscal.',
      })
      return
    }

    if (isEditing) {
      showToast({
        type: 'warning',
        message: 'Salve antes as edições deste registro.',
      })
      return
    }

    navigate(`${ROUTES.NF_FORM}/${serviceOrderId}/orcamento/${budgetId}`, {
      state: { from: pathname },
    })
  }

  if (!showButton) return null

  return (
    <Button onClick={handleClick} color="primary" variant="secondary">
      NFS-e
    </Button>
  )
}
